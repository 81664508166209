var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-container"},[_c('div',{staticClass:"box",style:([
         _vm.isHovered && _vm.type === 'A' && _vm.status !== 'disabled' ? { 'border': 'solid 1px #2a72e5', 'color': '#2a72e5' } : {},
         _vm.isHovered && _vm.type === 'B' && _vm.status !== 'disabled' ? { 'border': 'solid 1px #f7981c', 'color': '#f7981c' } : {},
         _vm.$mq === 'mobile' ? { width: '100%', 'justify-content': ''} : { 'justify-content': 'center' } ]),on:{"mouseover":function($event){_vm.isHovered=true},"mouseleave":function($event){_vm.isHovered=false}}},[_c('div',{staticClass:"function-name",style:([
           _vm.isHovered && _vm.type === 'A' ? { 'color': '#2a72e5' } : {},
           _vm.isHovered && _vm.type === 'B' ? { 'color': '#f7981c' } : {},
           _vm.status === 'selected' && _vm.type === 'A' ? { color: '#2a72e5' } : {},
           _vm.status === 'selected' && _vm.type === 'B' ? { color: '#ff7800' } : {},
           _vm.status === 'disabled' ? { 'color': '#bdc0c2' } : {}
         ])},[_vm._v(" "+_vm._s(_vm.functionName)+" ")])]),(_vm.status === 'disabled')?_c('div',{staticClass:"tooltip"},[_c('img',{staticStyle:{"margin-right":"85px"},attrs:{"src":require("@/assets/tooltip-mobile.png"),"alt":"","width":"6","height":"4"}}),_c('div',{staticClass:"tooltip-content"},[_vm._v(" This function will become available after the DAO contract is upgraded. ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }