var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"committee"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.tooltip !== '')?_c('img',{attrs:{"src":require("@/assets/tooltip.png"),"alt":"","width":"14","height":"14"}}):_vm._e(),_c('div',{staticClass:"tooltip",style:(_vm.tooltipWidth)},[_c('img',{attrs:{"src":require("@/assets/arrow-tooltip.png"),"alt":"","width":"4","height":"6"}}),_c('div',{staticClass:"tooltip-content"},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])])]),(typeof(_vm.content) === 'string')?_c('div',{staticClass:"content"},[(_vm.type === 'address' && _vm.$mq === 'mobile')?_c('span',{class:{
              'url': _vm.type === 'url',
              'description': _vm.type === 'description',
              'address': _vm.type === 'address',
              'description2': _vm.type === 'time',
            },on:{"click":function($event){return _vm.redirect(_vm.content)}}},[_vm._v(" "+_vm._s(_vm._f("hexSlicer")(_vm.content))+" ")]):_c('span',{class:{
              'url': _vm.type === 'url',
              'description': _vm.type === 'description',
              'address': _vm.type === 'address',
              'description2': _vm.type === 'time',
            },on:{"click":function($event){return _vm.redirect(_vm.content)}}},[_vm._v(" "+_vm._s(_vm.content)+" ")])]):_vm._e(),(_vm.type === 'time' && typeof(_vm.content) === 'number')?_c('div',{staticClass:"content"},[_c('span',{class:{ 'description2': _vm.type === 'time' }},[_vm._v(" "+_vm._s(_vm._f("date2")(_vm.content))+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }