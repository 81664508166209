var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
          primary: _vm.type === 'primary',
          'primary-typeB': _vm.type === 'primary-typeB',
          secondary: _vm.type === 'secondary',
          vote: _vm.type === 'vote',
          voteV2: _vm.type === 'voteV2',
          hide: _vm.type === 'hide',

          'primary-running': _vm.type === 'primary' && _vm.status === 'running',
          'primary-running-typeB': _vm.type === 'primary-typeB' && _vm.status === 'running',
          'secondary-running': _vm.type === 'secondary' && _vm.status === 'running',
          'vote-running': _vm.type === 'vote' && _vm.status === 'running',
          'voteV2-running': _vm.type === 'voteV2' && _vm.status === 'running',

          'primary-disabled': _vm.type === 'primary' && _vm.status === 'disabled',
          'primary-disabled-typeB': _vm.type === 'primary-typeB' && _vm.status === 'disabled',
          'secondary-disabled': _vm.type === 'secondary' && _vm.status === 'disabled',
          'vote-disabled': _vm.type === 'vote' && _vm.status === 'disabled',
          'voteV2-disabled': _vm.type === 'voteV2' && _vm.status === 'disabled',

          'running': _vm.status === 'running',
        },style:({ width: _vm.width }),attrs:{"disabled":_vm.status === 'disabled'},on:{"click":_vm.click}},[(_vm.status === 'running')?_c('div',{staticClass:"loader",class:{ 'loader-vote': _vm.type === 'vote' }}):_c('div',[_vm._v(_vm._s(_vm.name))])])}
var staticRenderFns = []

export { render, staticRenderFns }