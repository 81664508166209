var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-input",class:{
       'clickable': _vm.clickable,
       'error': _vm.error,
     },on:{"click":function($event){return _vm.click()}}},[(_vm.label !== '')?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.realValue),expression:"realValue"}],ref:"input",class:{
           'with-unit': _vm.unit !== '',
           'big': _vm.big,
           'no-unit-but-padding': _vm.forVoting,
         },style:(_vm.inputPadding),attrs:{"placeholder":_vm.hint,"readonly":_vm.readonly},domProps:{"value":(_vm.realValue)},on:{"keypress":_vm.keypress,"input":function($event){if($event.target.composing){ return; }_vm.realValue=$event.target.value}}}),(_vm.unit !== '')?_c('div',{ref:"unit"},[_vm._v(_vm._s(_vm.unit))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }